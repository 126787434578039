import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router';
import { showDeleteConfirmBox } from '../../components/DeleteConfirmBox';
import { CommonContext, ProgressContext } from '../../contexts';
import { createNewEmployee, deleteEmployee, editEmployee, getEmployees } from '../../data/mysql';
import styles from './AdminEmployees.module.css';

const AdminEmployees = () => {
  const { categories } = useContext(CommonContext);
  const { spinner } = useContext(ProgressContext);
  const { pathname } = useLocation();

  const [employees, setEmployees] = useState([]);
  const [newEmployee, setNewEmployee] = useState({ id: null, role: '', name: '', tel: '' });

  useEffect(() => {
    getEmployees().then(setEmployees).catch(console.error);
  }, []);

  /**
   * 항목을 추가한다.
   * 1. DB에 저장
   * 2. 화면에 표시
   */
  const handleAdd = async () => {
    try {
      spinner.start();

      // DB에 저장
      const result = await createNewEmployee({ role: newEmployee.role, name: newEmployee.name, tel: newEmployee.tel });

      if (!result) {
        throw new Error('등록에 실패했습니다.');
      }

      // 화면에 표시
      const newEmployees = [...employees];
      newEmployees.push({ ...newEmployee, id: result.id });

      setEmployees(newEmployees);
      setNewEmployee({ id: null, role: '', name: '', tel: '' });

      toast.success('등록되었습니다.');
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    } finally {
      spinner.stop();
    }
  };

  /**
   * 수정
   */
  const handleEdit = async (employee) => {
    try {
      spinner.start();

      // DB에 저장
      const result = await editEmployee(employee);

      if (!result) {
        throw new Error('수정 실패했습니다.');
      }

      toast.success('수정되었습니다.');
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    } finally {
      spinner.stop();
    }
  };

  /**
   * 삭제
   */
  const handleDelete = async (id) => {
    try {
      spinner.start();

      // DB에 저장
      const result = await deleteEmployee(id);

      if (!result) {
        throw new Error('삭제에 실패했습니다.');
      }

      // 화면에 표시
      const newEmployees = employees.filter((employee) => employee.id !== id);
      setEmployees(newEmployees);

      toast.success('삭제되었습니다.');
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    } finally {
      spinner.stop();
    }
  };

  /**
   * Input의 값을 변경한다.
   */
  const handleChange = ({ target: { name, value } }, idx) => {
    const updatedEmployees = [...employees];
    updatedEmployees[idx] = { ...updatedEmployees[idx], [name]: value };

    setEmployees(updatedEmployees);
  };

  return (
    <div id="primary" className="content-area">
      {/* <!--s:#content --> */}
      <div id="content" className="site-content">
        <article>
          <div>
            <div className="title-inner">
              <img src="/common/images/title.png" alt="" />
              <h1>{categories.find((category) => category.categoryCode === pathname.split('/')[4])?.categoryName}</h1>
            </div>
            <div style={{ height: 20 }}></div>
            <div style={{ clear: 'both' }}></div>
            <div className="title_subpage_solid_1"></div>
            <div style={{ height: 30 }}></div>

            <div className="">
              <table className="tbl_basic">
                <colgroup>
                  <col style={{ width: 80 }} />
                  <col style={{ width: 150 }} />
                  <col />
                  <col />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th>NO.</th>
                    <th>직 책</th>
                    <th>성 명</th>
                    <th>전화번호</th>
                    <th>-</th>
                  </tr>
                </thead>
                <tbody>
                  {employees &&
                    employees.map((item, idx) => (
                      <tr key={idx} id={idx}>
                        <td className={styles.td_employee}>{idx + 1}</td>
                        <td className={styles.td_employee}>
                          <input type="text" className={styles.textInput} name="role" value={item.role} onChange={(e) => handleChange(e, idx)} />
                        </td>
                        <td className={styles.td_employee}>
                          <input type="text" className={styles.textInput} name="name" value={item.name} onChange={(e) => handleChange(e, idx)} />
                        </td>
                        <td className={styles.td_employee}>
                          <input type="text" className={styles.textInput} name="tel" value={item.tel} onChange={(e) => handleChange(e, idx)} />
                        </td>
                        <td className={styles.td_employee}>
                          <button className={styles.button} onClick={() => handleEdit(item)}>
                            수정
                          </button>
                          <button className={styles.button} onClick={() => showDeleteConfirmBox(() => handleDelete(item.id))}>
                            삭제
                          </button>
                        </td>
                      </tr>
                    ))}
                  {/* 입력부 --------------------------------------------- */}
                  <tr>
                    <th className={styles.td_employee}>추가</th>
                    <td className={styles.td_employee}>
                      <input type="text" className={styles.textInput} name="role" value={newEmployee.role} onChange={(e) => setNewEmployee({ ...newEmployee, role: e.target.value })} />
                    </td>
                    <td className={styles.td_employee}>
                      <input type="text" className={styles.textInput} name="name" value={newEmployee.name} onChange={(e) => setNewEmployee({ ...newEmployee, name: e.target.value })} />
                    </td>
                    <td className={styles.td_employee}>
                      <input type="text" className={styles.textInput} name="tel" value={newEmployee.tel} onChange={(e) => setNewEmployee({ ...newEmployee, tel: e.target.value })} />
                    </td>
                    <td className={styles.td_employee}>
                      <button
                        className={[styles.wideButton, newEmployee.role === '' ? styles.disabled : ''].join(' ')} //
                        onClick={handleAdd} //
                        disabled={newEmployee.role === ''}
                      >
                        등록
                      </button>
                    </td>
                  </tr>
                  {/* 입력부 --------------------------------------------- */}
                </tbody>
              </table>
            </div>
          </div>
        </article>
      </div>
      {/* <!--//e: #content --> */}
    </div>
  );
};

export default AdminEmployees;
