import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CommonContext } from '../../contexts';
import { useState } from 'react';
import { getEmployees } from '../../data/mysql';

const InfoManagers = () => {
  const { categories } = useContext(CommonContext);
  const { pathname } = useLocation();

  const [grouped, setGrouped] = useState([]); // 그룹화된 임직원 리스트

  useEffect(() => {
    getEmployees()
      .then(makeGroupedEmployees) // 임직원 리스트를 그룹화
      .then(setGrouped) // 그룹화된 임직원 리스트를 상태로 저장
      .catch(console.error);
  }, []);

  /**
   * 임직원 리스트를 그룹화
   */
  const makeGroupedEmployees = (employees) => {
    const result = [];
    let current = null;

    employees.forEach(({ role, name, tel }) => {
      if (current && current.name === name) {
        current.roles.push(role); // 이름 같으면, 같은 그룹으로 묶음(이름, 전화번호)
      } else {
        current = { name, tel, roles: [role] };
        result.push(current);
      }
    });

    return result;
  };

  return (
    <div id="primary" className="content-area">
      {/* <!--s:#content --> */}
      <div id="content" className="site-content">
        <article>
          <div>
            <div className="title-inner">
              <img src="/common/images/title.png" alt="" />
              <h1>{categories.find((category) => category.categoryCode === pathname.split('/')[4])?.categoryName}</h1>
            </div>
            <div style={{ height: 20 }}></div>
            <div style={{ clear: 'both' }}></div>
            <div className="title_subpage_solid_1"></div>
            <div style={{ height: 30 }}></div>
            <div className="greetings_wrap">
              <div className="table_wrap">
                <table className="tbl_basic">
                  <colgroup>
                    <col />
                    <col style={{ width: 150 }} />
                    <col />
                    {/* <col style={{ width: 100 }} /> */}
                  </colgroup>
                  <thead>
                    <tr>
                      <th>직 책</th>
                      <th>성 명</th>
                      <th>사무실</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {grouped &&
                      grouped.map(({ id, roles, name, tel }) =>
                        roles.map((role, idx) => (
                          <tr key={`${id}_${idx}`}>
                            <th>{role}</th>
                            {idx === 0 && ( // 이름이 중복되는 경우, 첫번째 이름과 전화번호만 출력
                              <>
                                <td rowSpan={roles.length}>{name}</td>
                                <td rowSpan={roles.length}>{tel}</td>
                              </>
                            )}
                            <td>&nbsp;</td>
                          </tr>
                        )),
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* <!-- .entry-content --> */}
        </article>
      </div>
      {/* <!--//e: #content --> */}
    </div>
  );
};

export default InfoManagers;
