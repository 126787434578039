import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router';
import { showDeleteConfirmBox } from '../../components/DeleteConfirmBox';
import { CommonContext, ProgressContext } from '../../contexts';
import { createNewCommittee, deleteCommittee, editCommittee, getCommittees } from '../../data/mysql';
import styles from './AdminCommittees.module.css';

const AdminCommittees = () => {
  const { categories } = useContext(CommonContext);
  const { spinner } = useContext(ProgressContext);
  const { pathname } = useLocation();

  const [committees, setCommittees] = useState([]);
  const [newCommittee, setNewCommittee] = useState({ id: null, name: '', tel: '', email: '' });

  useEffect(() => {
    getCommittees().then(setCommittees).catch(console.error);
  }, []);

  /**
   * 항목을 추가한다.
   * 1. DB에 저장
   * 2. 화면에 표시
   */
  const handleAdd = async () => {
    try {
      spinner.start();

      // DB에 저장
      const result = await createNewCommittee({ name: newCommittee.name, tel: newCommittee.tel, email: newCommittee.email });

      if (!result) {
        throw new Error('등록에 실패했습니다.');
      }

      // 화면에 표시
      const newCommittees = [...committees];
      newCommittees.push({ ...newCommittee, id: result.id });

      setCommittees(newCommittees);
      setNewCommittee({ id: null, name: '', tel: '', email: '' });

      toast.success('등록되었습니다.');
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    } finally {
      spinner.stop();
    }
  };

  /**
   * 수정
   */
  const handleEdit = async (employee) => {
    try {
      spinner.start();

      // DB에 저장
      const result = await editCommittee(employee);

      if (!result) {
        throw new Error('수정 실패했습니다.');
      }

      toast.success('수정되었습니다.');
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    } finally {
      spinner.stop();
    }
  };

  /**
   * 삭제
   */
  const handleDelete = async (id) => {
    try {
      spinner.start();

      // DB에 저장
      const result = await deleteCommittee(id);

      if (!result) {
        throw new Error('삭제에 실패했습니다.');
      }

      // 화면에 표시
      const newCommittees = committees.filter((committee) => committee.id !== id);
      setCommittees(newCommittees);

      toast.success('삭제되었습니다.');
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    } finally {
      spinner.stop();
    }
  };

  /**
   * Input의 값을 변경한다.
   */
  const handleChange = ({ target: { name, value } }, idx) => {
    const updatedCommittees = [...committees];
    updatedCommittees[idx] = { ...updatedCommittees[idx], [name]: value };

    setCommittees(updatedCommittees);
  };

  return (
    <div id="primary" className="content-area">
      {/* <!--s:#content --> */}
      <div id="content" className="site-content">
        <article>
          <div>
            <div className="title-inner">
              <img src="/common/images/title.png" alt="" />
              <h1>{categories.find((category) => category.categoryCode === pathname.split('/')[4])?.categoryName}</h1>
            </div>
            <div style={{ height: 20 }}></div>
            <div style={{ clear: 'both' }}></div>
            <div className="title_subpage_solid_1"></div>
            <div style={{ height: 30 }}></div>

            <div className="">
              <table className="tbl_basic">
                <colgroup>
                  <col style={{ width: 80 }} />
                  <col style={{ width: 120 }} />
                  <col style={{ width: 150 }} />
                  <col />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th>NO.</th>
                    <th>성 명</th>
                    <th>전화번호</th>
                    <th>이메일</th>
                    <th>-</th>
                  </tr>
                </thead>
                <tbody>
                  {committees &&
                    committees.map((item, idx) => (
                      <tr key={idx} id={idx}>
                        <td className={styles.td_committee}>{idx + 1}</td>
                        <td className={styles.td_committee}>
                          <input type="text" className={styles.textInput} name="name" value={item.name} onChange={(e) => handleChange(e, idx)} />
                        </td>
                        <td className={styles.td_committee}>
                          <input type="text" className={styles.textInput} name="tel" value={item.tel} onChange={(e) => handleChange(e, idx)} />
                        </td>
                        <td className={styles.td_committee}>
                          <input type="text" className={styles.textInput} name="email" value={item.email} onChange={(e) => handleChange(e, idx)} />
                        </td>
                        <td className={styles.td_committee}>
                          <button className={styles.button} onClick={() => handleEdit(item)}>
                            수정
                          </button>
                          <button className={styles.button} onClick={() => showDeleteConfirmBox(() => handleDelete(item.id))}>
                            삭제
                          </button>
                        </td>
                      </tr>
                    ))}
                  {/* 입력부 --------------------------------------------- */}
                  <tr>
                    <th className={styles.td_committee}>추가</th>
                    <td className={styles.td_committee}>
                      <input type="text" className={styles.textInput} name="name" value={newCommittee.name} onChange={(e) => setNewCommittee({ ...newCommittee, name: e.target.value })} />
                    </td>
                    <td className={styles.td_committee}>
                      <input type="text" className={styles.textInput} name="tel" value={newCommittee.tel} onChange={(e) => setNewCommittee({ ...newCommittee, tel: e.target.value })} />
                    </td>
                    <td className={styles.td_committee}>
                      <input type="text" className={styles.textInput} name="email" value={newCommittee.email} onChange={(e) => setNewCommittee({ ...newCommittee, email: e.target.value })} />
                    </td>
                    <td className={styles.td_committee}>
                      <button
                        className={[styles.wideButton, newCommittee.name === '' ? styles.disabled : ''].join(' ')} //
                        onClick={handleAdd} //
                        disabled={newCommittee.name === ''}
                      >
                        등록
                      </button>
                    </td>
                  </tr>
                  {/* 입력부 --------------------------------------------- */}
                </tbody>
              </table>
            </div>
          </div>
        </article>
      </div>
      {/* <!--//e: #content --> */}
    </div>
  );
};

export default AdminCommittees;
