import React, { useRef, useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { ProgressContext, CommonContext, UserContext } from '../../contexts';
import { DataTypes } from '../../data/Types';
import { logout } from '../../data/firebase';

const MobileMenu = () => {
  const { spinner } = useContext(ProgressContext);

  const { user, dispatch } = useContext(UserContext);
  const { sideMenuVisible, categoryGroup, categories, groupCategoryRel, dispatchSideMenuVisible } = useContext(CommonContext);

  const navigate = useNavigate();

  const sideMenuRef = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', _handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', _handleClickOutside);
    };
  }, []);

  /**
   * 외부 영역을 클릭했을 경우, 사이드 메뉴(SideMenuBar)를 보이지 않도록 설정
   */
  const _handleClickOutside = ({ target }) => {
    // target ⇠ 곧 e.target을 가져온다.
    if (!sideMenuRef.current.contains(target)) {
      dispatchSideMenuVisible(false);
    }
  };

  /**
   * 로그아웃을 한다.
   */
  const _handleLogout = async () => {
    try {
      spinner.start();
      await logout();
      dispatch({});

      dispatchSideMenuVisible(false);
      navigate('/');
      toast.success('로그아웃 되었습니다');
    } catch (e) {
      console.log(e.message);
    } finally {
      spinner.stop();
    }
  };
  /**
   * 해당 메뉴를 클릭하면 하위 메뉴가 보인다.(또는 가린다)
   * @param {*} e
   */
  const _handleSubMenu = (e) => {
    const subMenuDisplay = e.currentTarget.getElementsByClassName('sub')[0].style.display;

    if (subMenuDisplay === 'block') {
      e.currentTarget.getElementsByClassName('sub')[0].style.display = 'none';
    } else {
      e.currentTarget.getElementsByClassName('sub')[0].style.display = 'block';
    }
  };

  return (
    <div className="mobile-menu">
      <div ref={sideMenuRef} id="main-sidebar" className={sideMenuVisible ? 'main-sidebar slide-in' : 'main-sidebar slide-out'}>
        <div data-simplesidebar="sbwrapper" style={{ position: 'relative', height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
          <nav id="gnb">
            <h2 className="hidden">네비게이션</h2>
            <div className="sidr-inner">
              <Link to="#" className="close-sb sidr-class-toggle-sidr-close" onClick={() => dispatchSideMenuVisible(false)}></Link>
            </div>
            <ul className="gnb_list">
              <li className="sidr-class-menu-item-mypage sidr-class-menu-item-mypage01">
                {!user.uid ? (
                  <Link to={`/member/unauthorized/${categories?.find((c) => c.categoryCode === 'login')?.id}/login`} onClick={() => dispatchSideMenuVisible(false)}>
                    로그인
                  </Link>
                ) : (
                  <Link to="#" onClick={_handleLogout}>
                    로그아웃
                  </Link>
                )}
              </li>
              <li className="sidr-class-menu-item-mypage sidr-class-menu-item-mypage01">
                {!user.uid ? (
                  <Link to={`/member/unauthorized/${categories?.find((c) => c.categoryCode === 'signup')?.id}/signup`} onClick={() => dispatchSideMenuVisible(false)}>
                    회원가입
                  </Link>
                ) : (
                  <Link to={`/member/authorized/${categories?.find((c) => c.categoryCode === 'edit')?.id}/edit`} onClick={() => dispatchSideMenuVisible(false)}>
                    정보수정
                  </Link>
                )}
              </li>

              {
                // 관리자 영역
                user.uid && user.level >= DataTypes.USER_LEVEL_MANAGER && (
                  <>
                    <li className="sidr-class-menu-item-mypage sidr-class-menu-item-mypage01">
                      <Link to="/admin/members">회원리스트</Link>
                    </li>
                    <li className="sidr-class-menu-item-mypage sidr-class-menu-item-mypage01">
                      <Link to="/admin/rollingLinks">메인롤링사진</Link>
                    </li>
                  </>
                )
              }
              <li className="sidr-class-menu-item-mypage sidr-class-menu-item-mypage01">
                <Link to="/">&nbsp;</Link>
              </li>
              <li className="sidr-class-menu-item-mypage sidr-class-menu-item-mypage01">
                <a href="https://acmsnet.org" target="_blank" rel="noreferrer">
                  [ACMS]
                </a>
              </li>

              {categoryGroup
                .filter((group) => group.public === 'Y')
                .map((group) => {
                  return (
                    <li key={group.id} className="depth1 hide-1" onClick={_handleSubMenu}>
                      <Link to="#" className="main">
                        {group.groupName}
                        <span></span>
                      </Link>
                      <ul className="sub slide-up" style={{ display: 'none' }}>
                        {groupCategoryRel
                          .filter((rel) => rel.groupId === group.id)
                          .map((result) => {
                            const currentCategory = categories.find((category) => category.id === result.categoryId);
                            return (
                              <li key={currentCategory.id}>
                                <Link
                                  to={`/${currentCategory.categoryType}/${group.groupCode}/${currentCategory.id}/${currentCategory.categoryType === 'board' ? 'list' : currentCategory.categoryCode}`}
                                  onClick={() => dispatchSideMenuVisible(false)}
                                >
                                  {currentCategory.categoryName}
                                </Link>
                              </li>
                            );
                          })}
                      </ul>
                    </li>
                  );
                })}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
